import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC0leHbbBrD2JEU-WnmnFHdNRUOYZu9bJ8",
  authDomain: "wolfgamexyz.firebaseapp.com",
  projectId: "wolfgamexyz",
  storageBucket: "wolfgamexyz.appspot.com",
  messagingSenderId: "976121521796",
  appId: "1:976121521796:web:cb94f6057a72f3871e44d1",
  measurementId: "G-939F28NSXL",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
