import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import fileSaver from "file-saver";

import { getOpenSeaAsset, WolfGameAssetResponse } from "./api";
import { analytics } from "./utilities/firebase";

import styles from "./App.module.css";

const storage = getStorage();

const WOLF_GAME_ANIMAL_ADDRESS = "0x7f36182dee28c45de6072a34d29855bae76dbe2f";

const App = () => {
  const [animalNumber, setAnimalNumber] = useState("16");
  const [currentAnimalNumber, setCurrentAnimalNumber] = useState("16");
  const [backgroundColor, setBackgroundColor] = useState("1F2225");
  const [isTransparent, setIsTransparent] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [animalData, setAnimalData] = useState<WolfGameAssetResponse>();
  const [customBackground, setCustomBackground] = useState("");

  useEffect(() => {
    (async () => {
      setFetching(true);
      const res = await getOpenSeaAsset(
        WOLF_GAME_ANIMAL_ADDRESS,
        currentAnimalNumber
      );

      try {
        const animalRef = ref(storage, `animalPng/${currentAnimalNumber}.png`);
        const image_url = await getDownloadURL(animalRef);

        const name = res.data?.name || "";
        setAnimalData({ name, image_url });
        setFetching(false);
      } catch (e) {
        alert(`Problem fetch data for animal: #${currentAnimalNumber}`);
        setFetching(false);
      }
    })();
  }, [currentAnimalNumber]);

  useEffect(() => {
    // @ts-ignore
    const canvas = document.getElementById("canvas");
    // @ts-ignore
    const context = canvas?.getContext("2d");

    if (!context) {
      return;
    }

    context.clearRect(0, 0, 350, 350);

    if (customBackground) {
      const bg = new Image();
      bg.onload = () => {
        const { width, height } = bg;
        const isLandscape = width > height;
        let bgWidth, bgHeight;
        let x = 0;
        let y = 0;

        if (isLandscape) {
          const scaleFactor = 350 / bg.height;
          bgHeight = 350;
          bgWidth = scaleFactor * bg.width;
          x = -((bgWidth - 350) / 2);
        } else {
          const scaleFactor = 350 / bg.width;
          bgWidth = 350;
          bgHeight = scaleFactor * bg.height;
          y = -((bgHeight - 350) / 2);
        }

        context.drawImage(bg, x, y, bgWidth, bgHeight);

        const image = new Image();
        image.onload = () => {
          context.drawImage(image, 10, 10, 330, 330);
        };
        image.setAttribute("crossorigin", "anonymous");
        // @ts-ignore
        image.src = animalData?.image_url;
      };
      // @ts-ignore
      bg.src = customBackground;
    } else {
      const fillColor = isTransparent ? "#00000000" : `#${backgroundColor}`;
      context.fillStyle = fillColor;
      context.fillRect(0, 0, 350, 350);

      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 10, 10, 330, 330);
      };
      image.setAttribute("crossorigin", "anonymous");
      // @ts-ignore
      image.src = animalData?.image_url;
    }
  }, [animalData?.image_url, backgroundColor, isTransparent, customBackground]);

  const onButtonClick = async () => {
    const canvas = document.getElementById("canvas");
    // @ts-ignore
    const dataUrl = canvas.toDataURL("image/png");

    const animalName = animalData?.name || "animal";

    logEvent(analytics, "pfp_downloaded", {
      animalName,
    });

    fileSaver.saveAs(dataUrl, animalName);
  };

  const onGenerateClick = () => {
    logEvent(analytics, "pfp_generated", {
      animalNumber: animalNumber,
      backgroundColor,
    });
    setCurrentAnimalNumber(animalNumber);
  };

  // @ts-ignore
  const onImageUpload = (e) => {
    const image = e.target.files[0];
    setIsTransparent(true);
    setCustomBackground(URL.createObjectURL(image));
  };

  if (!animalData) {
    return null;
  }

  return (
    <div className={styles.App}>
      <div className={styles.header} style={{ marginBottom: "20px" }}>
        WOLFGAME PFP
      </div>
      <label className={styles.textInputContainer}>
        <span className={styles.h3}>Animal number : #</span>
        <input
          type="number"
          name="animal-number"
          value={animalNumber}
          onChange={({ target: { value } }) => setAnimalNumber(value)}
          className={styles.textInput}
        />
      </label>
      <div className={styles.textInputContainer}>
        <div className={styles.h3}>Background Color: #</div>
        <input
          name="background-color"
          value={backgroundColor}
          onChange={({ target: { value } }) => setBackgroundColor(value)}
          className={styles.textInput}
        />
      </div>
      <div className={styles.textInputContainer}>
        <div className={styles.h3}>Transparent BG:</div>
        <label className={styles.switch}>
          <input
            type="checkbox"
            // @ts-ignore
            value={isTransparent}
            onChange={() => setIsTransparent((prev) => !prev)}
          />
          <div className={styles.slider} />
        </label>
      </div>
      <div className={styles.textInputContainer}>
        <div className={styles.h3}>Custom Background: </div>
        <label
          style={{
            marginLeft: "10px",
            backgroundColor: "transparent",
            color: "white",
            border: "1px solid white",
            width: "80px",
            height: "30px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <input
            id="customBackground"
            type="file"
            accept="image/*"
            onChange={onImageUpload}
            style={{ display: "none" }}
          />
          Upload
        </label>
        {!!customBackground && (
          <div
            style={{
              width: "30px",
              height: "100%",
              cursor: "pointer",
            }}
            // @ts-ignore
            onClick={() => setCustomBackground(null)}
          >
            ❌
          </div>
        )}
      </div>
      <div className={styles.h2} style={{ marginTop: "20px" }}>
        {animalData.name}
      </div>
      <div
        className={styles.pfpContainer}
        style={{
          marginBottom: "30px",
        }}
      >
        <canvas id="canvas" width="350px" height="350px" />
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={onGenerateClick}
          className={styles.submitButton}
          style={{ marginRight: "20px" }}
        >
          {fetching ? "..." : "Generate"}
        </button>
        <button onClick={onButtonClick} className={styles.submitButton}>
          Download
        </button>
      </div>
    </div>
  );
};

export default App;
