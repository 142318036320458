import { create, ApiResponse } from "apisauce";

const OPEN_SEA_API = create({
  baseURL: "https://api.opensea.io/api/v1/",
});

export type WolfGameAssetResponse = {
  name: string;
  image_url: string;
};

export const getOpenSeaAsset = async (
  collectionAddress: string,
  tokenId: string
) => {
  const result: ApiResponse<WolfGameAssetResponse> = await OPEN_SEA_API.get(
    `asset/${collectionAddress}/${tokenId}`
  );

  return result;
};
